
import React from 'react'
import { lazy, Suspense, useEffect } from "react"
import { Switch, BrowserRouter } from "react-router-dom";
import { PublicRoute } from './route-public';

const Home = lazy(() => import('pages/home').then(module => ({ default: module.Home })));
const History = lazy(() => import('pages/home').then(module => ({ default: module.History })));
const HistoryReport = lazy(() => import('pages/home').then(module => ({ default: module.HistoryReport })));

const PatientInformation = lazy(() => import('pages/patient-information').then(module => ({ default: module.PatientInformation })));
const PatientIsolation = lazy(() => import('pages/patient-isolation').then(module => ({ default: module.PatientIsolation })));

const Template = lazy(() => import('pages/template').then(module => ({ default: module.Template })))
const Template2 = lazy(() => import('pages/template').then(module => ({ default: module.Template2 })));

const PrimarySurvey = lazy(() => import('pages/primary-survey').then(module => ({ default: module.PrimarySurvey })));
const PrimarySurveyA = lazy(() => import('pages/primary-survey').then(module => ({ default: module.PrimarySurveyA })));
const PrimarySurveyB = lazy(() => import('pages/primary-survey').then(module => ({ default: module.PrimarySurveyB })));
const PrimarySurveyC = lazy(() => import('pages/primary-survey').then(module => ({ default: module.PrimarySurveyC })));
const PrimarySurveyD = lazy(() => import('pages/primary-survey').then(module => ({ default: module.PrimarySurveyD })));
const PrimarySurveyE = lazy(() => import('pages/primary-survey').then(module => ({ default: module.PrimarySurveyE })));

const Case = lazy(() => import('pages/case').then(module => ({ default: module.Case })));

const ShockIntakeOutput = lazy(() => import('pages/case').then(module => ({ default: module.ShockIntakeOutput })));
const ShockSummary = lazy(() => import('pages/case').then(module => ({ default: module.ShockSummary })));
const ShockDischargeType = lazy(() => import('pages/case').then(module => ({ default: module.ShockDischargeType })));
const ShockReport = lazy(() => import('pages/case').then(module => ({ default: module.ShockReport })));
const ShockGoalCareWard = lazy(() => import('pages/case').then(module => ({ default: module.ShockGoalCareWard })));

const ShockChestAssessment = lazy(() => import('pages/case').then(module => ({ default: module.ShockChestAssessment })));
const ShockTummyAssessment = lazy(() => import('pages/case').then(module => ({ default: module.ShockTummyAssessment })));
const ShockUnknowAssessment = lazy(() => import('pages/case').then(module => ({ default: module.ShockUnknowAssessment })));

const ShockChestDiagnosis = lazy(() => import('pages/case').then(module => ({ default: module.ShockChestDiagnosis })));
const ShockTummyDiagnosis = lazy(() => import('pages/case').then(module => ({ default: module.ShockTummyDiagnosis })));
const ShockUnknownDiagnosis = lazy(() => import('pages/case').then(module => ({ default: module.ShockUnknownDiagnosis })));


const MultipleInjury = lazy(() => import('pages/case').then(module => ({ default: module.MultipleInjury })));
const MultipleInjuryA = lazy(() => import('pages/case').then(module => ({ default: module.MultipleInjuryA })));
const MultipleInjuryB = lazy(() => import('pages/case').then(module => ({ default: module.MultipleInjuryB })));
const MultipleInjuryC = lazy(() => import('pages/case').then(module => ({ default: module.MultipleInjuryC })));
const MultipleInjuryD = lazy(() => import('pages/case').then(module => ({ default: module.MultipleInjuryD })));
const MultipleInjuryE = lazy(() => import('pages/case').then(module => ({ default: module.MultipleInjuryE })));
const MultipleSummary = lazy(() => import('pages/case').then(module => ({ default: module.MultipleSummary })));
const MultipleAssessment = lazy(() => import('pages/case').then(module => ({ default: module.MultipleAssessment })));

const TraumaticBrainInjury = lazy(() => import('pages/case').then(module => ({ default: module.TraumaticBrainInjury })));
const TraumaticBrainInjury2 = lazy(() => import('pages/case').then(module => ({ default: module.TraumaticBrainInjury2 })));
const TraumaticBrainInjury3 = lazy(() => import('pages/case').then(module => ({ default: module.TraumaticBrainInjury3 })));
const TraumaticBrainInjury4 = lazy(() => import('pages/case').then(module => ({ default: module.TraumaticBrainInjury4 })));

const NursingCareRecord = lazy(() => import('pages/case').then(module => ({ default: module.NursingCareRecord })));
const NursingCareRecordVitalSign = lazy(() => import('pages/case').then(module => ({ default: module.NursingCareRecordVitalSign })));
const NursingCareRecordAtscene = lazy(() => import('pages/case').then(module => ({ default: module.NursingCareRecordAtscene })));
const NursingCareRecordOutcome = lazy(() => import('pages/case').then(module => ({ default: module.NursingCareRecordOutcome })));
const NursingCareRecordMaintenance = lazy(() => import('pages/case').then(module => ({ default: module.NursingCareRecordMaintenance })));
const NursingCareRecordPrintout = lazy(() => import('pages/case').then(module => ({ default: module.NursingCareRecordPrintout })));

const MtbiHome = lazy(() => import('pages/web-mtbi').then(module => ({ default: module.MtbiHome })));
const MtbiLogIn = lazy(() => import('pages/web-mtbi').then(module => ({ default: module.MtbiLogIn })));
const MtbiRegister = lazy(() => import('pages/web-mtbi').then(module => ({ default: module.MtbiRegister })));

const MtbiVideo = lazy(() => import('pages/web-mtbi').then(module => ({ default: module.MtbiVideo })));

const MtbiAssessment1 = lazy(() => import('pages/web-mtbi').then(module => ({ default: module.MtbiAssessment1 })));
const MtbiAssessment2 = lazy(() => import('pages/web-mtbi').then(module => ({ default: module.MtbiAssessment2 })));
const MtbiAssessment3 = lazy(() => import('pages/web-mtbi').then(module => ({ default: module.MtbiAssessment3 })));
const MtbiAssessment4 = lazy(() => import('pages/web-mtbi').then(module => ({ default: module.MtbiAssessment4 })));

// const MtbiAdmin = lazy(() => import('pages/mtbi-admin/home'));
const ClassHome = lazy(() => import('pages/mtbi-admin/home'));


export default function Routes() {
    useEffect(() => {
        const checkBodySize = () => {
            let body_width = document.documentElement.offsetWidth + 'px'
            let body_height = document.documentElement.offsetHeight + 'px'
            document.documentElement.style.setProperty('--body-width', body_width);
            document.documentElement.style.setProperty('--body-height', body_height);
        }

        window.addEventListener("resize", checkBodySize);
        // 
        checkBodySize()
        return () => window.removeEventListener("resize", checkBodySize)
    }, [])

    return (
        <BrowserRouter>
            <Suspense fallback={<div />}>
                <Switch>
                    <PublicRoute exact path="/" component={Home} />
                    <PublicRoute path="/home" component={Home} />
                    <PublicRoute path="/history" component={History} />
                    <PublicRoute path="/history-report/:taskId?/:serviceType?" component={HistoryReport} />

                    <PublicRoute exact path="/case" component={Case} />

                    {/* shock */}
                    <PublicRoute path="/shock/patient/information/:taskId?" component={PatientInformation} serviceType="01" servicePath="shock" />
                    <PublicRoute path="/shock/patient/isolation/:taskId?" component={PatientIsolation} serviceType="01" servicePath="shock" />
                    <PublicRoute exact path="/shock/survey/:taskId?" component={PrimarySurvey} serviceType="01" servicePath="shock" />
                    <PublicRoute path="/shock/survey/a/:taskId?" component={PrimarySurveyA} serviceType="01" servicePath="shock" />
                    <PublicRoute path="/shock/survey/b/:taskId?" component={PrimarySurveyB} serviceType="01" servicePath="shock" />
                    <PublicRoute path="/shock/survey/c/:taskId?" component={PrimarySurveyC} serviceType="01" servicePath="shock" />
                    <PublicRoute path="/shock/survey/d/:taskId?" component={PrimarySurveyD} serviceType="01" servicePath="shock" />
                    <PublicRoute path="/shock/survey/e/:taskId?" component={PrimarySurveyE} serviceType="01" servicePath="shock" />
                    <PublicRoute path="/case/shock-intakeoutput/:injuredpart?/:taskId?" component={ShockIntakeOutput} />
                    <PublicRoute path="/case/shock-summary/:injuredpart?/:taskId?" component={ShockSummary} />
                    <PublicRoute path="/case/shock-discharge/:injuredpart?/:taskId?" component={ShockDischargeType} />
                    <PublicRoute path="/case/shock-report/:taskId?" component={ShockReport} />
                    <PublicRoute path="/case/shock-goal-care-ward/:injuredpart?/:taskId?" component={ShockGoalCareWard} />
                    <PublicRoute path="/case/shock-assessment/chest/:taskId?" component={ShockChestAssessment} />
                    <PublicRoute path="/case/shock-assessment/tummy/:taskId?" component={ShockTummyAssessment} />
                    <PublicRoute path="/case/shock-assessment/unknown/:taskId?" component={ShockUnknowAssessment} />
                    <PublicRoute path="/case/shock-diagnosis/chest/:taskId?" component={ShockChestDiagnosis} />
                    <PublicRoute path="/case/shock-diagnosis/tummy/:taskId?" component={ShockTummyDiagnosis} />
                    <PublicRoute path="/case/shock-diagnosis/unknown/:taskId?" component={ShockUnknownDiagnosis} />

                    {/* multiple */}
                    <PublicRoute path="/multiple/patient/information/:taskId?" component={PatientInformation} serviceType="02" servicePath="multiple" />
                    <PublicRoute path="/multiple/patient/isolation/:taskId?" component={PatientIsolation} serviceType="02" servicePath="multiple" />
                    <PublicRoute exact path="/multiple/survey/:taskId?" component={PrimarySurvey} servicePath="multiple" />
                    <PublicRoute path="/multiple/survey/a/:taskId?" component={PrimarySurveyA} serviceType="02" servicePath="multiple" />
                    <PublicRoute path="/multiple/survey/b/:taskId?" component={PrimarySurveyB} serviceType="02" servicePath="multiple" />
                    <PublicRoute path="/multiple/survey/c/:taskId?" component={PrimarySurveyC} serviceType="02" servicePath="multiple" />
                    <PublicRoute path="/multiple/survey/d/:taskId?" component={PrimarySurveyD} serviceType="02" servicePath="multiple" />
                    <PublicRoute path="/multiple/survey/e/:taskId?" component={PrimarySurveyE} serviceType="02" servicePath="multiple" />
                    <PublicRoute path="/case/multiple/:taskId?" component={MultipleInjury} />
                    <PublicRoute path="/case/multiple-a/:taskId?" component={MultipleInjuryA} />
                    <PublicRoute path="/case/multiple-b/:taskId?" component={MultipleInjuryB} />
                    <PublicRoute path="/case/multiple-c/:taskId?" component={MultipleInjuryC} />
                    <PublicRoute path="/case/multiple-d/:taskId?" component={MultipleInjuryD} />
                    <PublicRoute path="/case/multiple-e/:taskId?" component={MultipleInjuryE} />
                    <PublicRoute path="/case/multiple-summary/:taskId?" component={MultipleSummary} />
                    <PublicRoute path="/case/multiple-assessment/:taskId?" component={MultipleAssessment} />

                    {/* traumatic */}
                    <PublicRoute path="/traumatic/patient/information/:taskId?" component={PatientInformation} serviceType="03" servicePath="traumatic" />
                    <PublicRoute path="/traumatic/patient/isolation/:taskId?" component={PatientIsolation} serviceType="03" servicePath="traumatic" />
                    <PublicRoute exact path="/traumatic/survey/:taskId?" component={PrimarySurvey} servicePath="traumatic" serviceType="03" />
                    <PublicRoute path="/traumatic/survey/a/:taskId?" component={PrimarySurveyA} serviceType="03" servicePath="traumatic" />
                    <PublicRoute path="/traumatic/survey/b/:taskId?" component={PrimarySurveyB} serviceType="03" servicePath="traumatic" />
                    <PublicRoute path="/traumatic/survey/c/:taskId?" component={PrimarySurveyC} serviceType="03" servicePath="traumatic" />
                    <PublicRoute path="/traumatic/survey/d/:taskId?" component={PrimarySurveyD} serviceType="03" servicePath="traumatic" />
                    <PublicRoute path="/traumatic/survey/e/:taskId?" component={PrimarySurveyE} serviceType="03" servicePath="traumatic" />
                    <PublicRoute path="/case/traumatic/:taskId?" component={TraumaticBrainInjury} />
                    <PublicRoute path="/case/traumatic-2/:taskId?" component={TraumaticBrainInjury2} />
                    <PublicRoute path="/case/traumatic-3/:taskId?" component={TraumaticBrainInjury3} />
                    <PublicRoute path="/case/traumatic-4/:taskId?" component={TraumaticBrainInjury4} />

                    {/* EMS */}
                    <PublicRoute path="/nursing/patient/information/:taskId?" component={PatientInformation} serviceType="04" servicePath="nursing" />
                    <PublicRoute path="/nursing/patient/isolation/:taskId?" component={PatientIsolation} serviceType="04" servicePath="nursing" />
                    <PublicRoute exact path="/nursing/survey/:taskId?" component={PrimarySurvey} servicePath="nursing" />
                    <PublicRoute path="/nursing/survey/a/:taskId?" component={PrimarySurveyA} serviceType="04" servicePath="nursing" />
                    <PublicRoute path="/nursing/survey/b/:taskId?" component={PrimarySurveyB} serviceType="04" servicePath="nursing" />
                    <PublicRoute path="/nursing/survey/c/:taskId?" component={PrimarySurveyC} serviceType="04" servicePath="nursing" />
                    <PublicRoute path="/nursing/survey/d/:taskId?" component={PrimarySurveyD} serviceType="04" servicePath="nursing" />
                    <PublicRoute path="/nursing/survey/e/:taskId?" component={PrimarySurveyE} serviceType="04" servicePath="nursing" />
                    <PublicRoute path="/case/nursing/:taskId?" component={NursingCareRecord} />
                    <PublicRoute path="/case/nursing-vitalsign/:taskId?" component={NursingCareRecordVitalSign} />

                    <PublicRoute path="/case/nursing-atscene/:taskId?" component={NursingCareRecordAtscene} />
                    <PublicRoute path="/case/nursing-outcome/:taskId?" component={NursingCareRecordOutcome} />
                    <PublicRoute path="/case/nursing-maintenance/:taskId?" component={NursingCareRecordMaintenance} />
                    <PublicRoute path="/case/nursing-printout/:taskId?" component={NursingCareRecordPrintout} />


                    {/*web mtbi */}
                    <PublicRoute exact path="/mtbi/" component={MtbiHome} />
                    <PublicRoute path="/mtbi/home" component={MtbiHome} />
                    <PublicRoute path="/mtbi/login" component={MtbiLogIn} />
                    <PublicRoute path="/mtbi/register" component={MtbiRegister} />

                    <PublicRoute path="/mtbi/video" component={MtbiVideo} />

                    <PublicRoute path="/mtbi/assessment1" component={MtbiAssessment1} />
                    <PublicRoute path="/mtbi/assessment2/:timeName?" component={MtbiAssessment2} />
                    <PublicRoute path="/mtbi/assessment3/:timeName?" component={MtbiAssessment3} />
                    <PublicRoute path="/mtbi/assessment4/:timeName?" component={MtbiAssessment4} />

                    {/* <PublicRoute path="/mtbi/adminMtbi" component={MtbiAdmin} /> */}
                    <PublicRoute path="/mtbi/admin" component={ClassHome} />

                    {/* template */}
                    <PublicRoute path="/template" component={Template} />
                    <PublicRoute path="/template2/:taskId?" component={Template2} />

                    {/* not found page */}
                    <PublicRoute path="*" component={() => { return <div className="h1 p-5 text-danger">404!!!!<br /> Not Found Page</div> }} />
                </Switch>
            </Suspense>
        </BrowserRouter>
    )
}